import React from "react";
import Button from "./Button";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="headerContainer ">
      <div className="header">
        <h1>Learn Spanish</h1>
        <h1 className="sub-heading">With Confidence</h1>
      </div>
      <br />
      <blockquote className="quote">
        One on one personalised lessons with a native speaker. Become fluent in
        9 months
      </blockquote>
      <br />
     {/* Use Link to navigate to the "/contact" page */}
     <Link to="/book-a-session">
        <Button className={"btn-book-now"} label={"Get started here!"} />
      </Link>
    </div>
  );
};

export default Home;

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import About from "./Components/About";

import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import Home from "./Components/Header";
import NavBar from "./Components/NavBar";
import ProfileForm from "./Components/ProfileForm";

function App() {
  return (
    <div>
    <Router>
    <NavBar />
      <div className="App-header">    
        <Switch>          
          <Route path="/" exact component={Home} />
          <Route path="/contact" component={Contact}/>
          <Route path="/about" component={About} />
          <Route path="/book-a-session" component={ProfileForm} />
          {/* <Route path="/blog" component={Blog} /> */}
        </Switch>
      </div>
    </Router>

    <Footer/>
    </div>
  );
}

export default App;

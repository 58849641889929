import React, { useState } from "react";
import axios from "axios";
import { Alert, Space } from 'antd';


const ProfileForm = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    aboutMe: "",
    spanishProficiency: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("https://learn-spanish-server.onrender.com/book-a-session", formData);
      setShowAlert(true); // Show the alert when the form is successfully submitted
      console.log("Form submitted successfully!");
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const Popover = () => {
    return (
    <>
    <br></br>
    <Space
    direction="vertical"
    style={{
      width: '100%',

    }}
  >
    <Alert className="alertSuccess" message="Success! One of our tutors will be in touch shortly" type="success" showIcon={true} />
    
    </Space>
    </>
    
  )}

  return (
    <div >
      {/* Display Alert based on showAlert state */}
      {showAlert && <Popover />}
      <div className="bookingForm">
        <h2>Book a FREE 15 minute consultation</h2>
        <br />
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="aboutMe">Name</label>
            <textarea
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              rows={1}
              placeholder="Your name"
              required
            />
          </div>
          <div>
            <label htmlFor="aboutMe">Email</label>
            <textarea
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              rows={1}
              placeholder="example@email.com"
              required
            />
          </div>
          <div>
            <label htmlFor="aboutMe">About Me</label>
            <textarea
              id="aboutMe"
              name="aboutMe"
              value={formData.aboutMe}
              onChange={handleChange}
              rows={4}
              placeholder="Tell us about yourself or why you would like to learn Spanish"
              required
            />
          </div>

          <div>
            <label htmlFor="spanishProficiency">Spanish Proficiency</label>
            <select
              id="spanishProficiency"
              name="spanishProficiency"
              value={formData.spanishProficiency}
              onChange={handleChange}
              required
            >
              <option value="">Select your proficiency</option>
              <option value="beginner">Beginner</option>
              <option value="intermediate">Intermediate</option>
              <option value="advanced">Advanced</option>
            </select>
          </div>
          <div>
            <button onClick={Popover} type="submit">Submit</button>
          </div>
        </form>
        
      </div>

    </div>
  );
};

export default ProfileForm;

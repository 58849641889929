import React from 'react';


class Contact extends React.Component {
    state = {  }
    render() { 
        return ( 
            <div className="App-header">
                
                <div className="contact">
                
                </div>
              
                
            
            </div>
         );
    }
}
 
export default Contact;
import React from "react";
import valeprof from "../Images/valeprof2.jpg"
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Button from "./Button";

const About = () => {
  return (
    <>
    <div className="aboutMe">
        <h1>About Me</h1>
        <br />
      <p>Hi, </p>
      <p>
        I'm Vale, a 33-year-old from Argentina who has found her second home in
        the picturesque landscapes of New Zealand alongside my wonderful
        husband. Teaching is not just my profession; it's my passion. I
        absolutely adore helping others learn and grow. When I'm not in the
        classroom, you'll often find me strolling through the breathtaking
        scenery of New Zealand, taking in the natural beauty and tranquility
        that surrounds us. It's in these moments that I find inspiration and
        recharge my spirit. Beyond teaching and nature, I have two other great
        loves: books and movies. There's something truly magical about getting
        lost in a good book or immersing myself in the captivating stories of
        cinema. As your Spanish teacher, I'm here to offer patient guidance and
        support, regardless of your current level. Whether you're taking your
        first steps in Spanish or fine-tuning your advanced skills, we'll embark
        on this language-learning journey together. Let's make your Spanish
        language goals a reality!
       <img className="profilePic" src={valeprof} alt="profile"></img>

      </p>
      <br />
      <Link to="/book-a-session">
        <Button className={"btn-book-now"} label={"Book a session with me. Its Free!"} />
      </Link>
     
    </div>
   
    </>
  );
};

export default About;

import React from "react";
import { Link } from "react-router-dom";

const NavBar =() => {
    return (
      <div className="nav-banner ">
        <ul class="nav">
          <Link to="/">
            <li class="nav-item">
            <div class="nav-link " aria-current="page">Home</div>
            </li>
          </Link>
          <Link to="/about">
            <li class="nav-item "><div class="nav-link" >About</div></li>
          </Link>
         
          <Link to="/book-a-session">
            <li class="nav-item"><div class="nav-link">Book A Session</div></li>
          </Link>
          <Link to="/">
            <li class="nav-item"><div class="nav-link">FAQ</div></li>
          </Link>
        </ul>
      </div>
    );
}

export default NavBar;

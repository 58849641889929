import React from 'react';

const Button= ({label,className,type}) => { 
        return ( 
            <>
            <button type={type}className={className}>{label}</button>
            </>
         );
    
}
 
export default Button;